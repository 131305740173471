import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { setSnackbar } from '../store/snackbarSlice';

export default function SnackbarComponent() {
  const snackbarState = useSelector((state: RootState) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(
      setSnackbar({
        open: false,
        message: snackbarState.snackbar.message,
        severity: snackbarState.snackbar.severity
      })
    );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={5000}
      open={snackbarState.snackbar.open}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={snackbarState.snackbar.severity}>
        {snackbarState.snackbar.message}
      </Alert>
    </Snackbar>
  );
}
