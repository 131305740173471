import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MOBILE_BREAKPOINT } from '../constants';
import MobileMenu from '../components/topMenu/MobileMenu';
import DesktopMenu from '../components/topMenu/DesktopMenu';
import DraftsListViewer from '../components/drafts/DraftsListViewer';
import SnackbarComponent from '../components/SnackbarComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import SignIn from '../components/auth/SignIn';
import { setCurrentDraft, setDraftInProgress, setDrafts } from '../store/draftsSlice';
import { REFRESH_INTERVAL } from '../components/drafts/constants';
import { fetchDraft, fetchDrafts } from '../components/drafts/utils';
import { AxiosResponse } from 'axios';
import { Draft, DraftListType } from '../components/drafts/types';
import { setSnackbar } from '../store/snackbarSlice';
import ProgressLine from '../components/ProgressLine';
import { setLoading } from '../store/loadingSlice';
import { useInterval } from '../components/upload/utils';
import { logOutIfUnauthorized } from '../utils';

export default function Layout() {
  const { id } = useParams();
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT);
  const auth = useSelector((state: RootState) => state.auth);
  const draftInProgress = useSelector((state: RootState) => state.drafts.draftInProgress);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.loggedIn && auth.user?.token) {
      getAllDrafts();
    }
  }, [auth.user?.token]);

  const getAllDrafts = () => {
    // Fetch drafts based on authenticated user
    dispatch(setLoading(true));
    const draftsResponse = fetchDrafts();
    if (draftsResponse) {
      draftsResponse
        .then((response: AxiosResponse<DraftListType[]>) => {
          dispatch(setDrafts(response.data));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (logOutIfUnauthorized(err?.response)) {
            return;
          }
          dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
        });
    } else {
      dispatch(setLoading(false));
      dispatch(
        setSnackbar({
          open: true,
          message: 'Error loading drafts.',
          severity: 'error'
        })
      );
    }
  };

  const fetchNewDraftStatus = () => {
    if (draftInProgress) {
      const draftResponse = fetchDraft(draftInProgress);
      if (draftResponse) {
        draftResponse
          .then((res: AxiosResponse<Draft>) => {
            if (
              res.data.versions.length > 0 &&
              !res.data.versions.some(
                (version) => version.status === 'received' || version.status === 'in_progress'
              )
            ) {
              if (id !== draftInProgress) {
                dispatch(
                  setSnackbar({
                    open: true,
                    message: `${res.data.title} is ready.`,
                    severity: 'success'
                  })
                );
              } else {
                dispatch(setCurrentDraft(res.data));
              }
              dispatch(setDraftInProgress(undefined));
              getAllDrafts();
            }
          })
          .catch((err) => logOutIfUnauthorized(err?.response));
      }
    }
  };

  useInterval(fetchNewDraftStatus, REFRESH_INTERVAL);

  return (
    <Box className="App" display="flex" flexDirection="column">
      {isMobile ? <MobileMenu getAllDrafts={getAllDrafts} /> : <DesktopMenu />}
      <Box display="flex" flexGrow={1}>
        {!isMobile && <DraftsListViewer isMobile={isMobile} getAllDrafts={getAllDrafts} />}
        <Outlet />
      </Box>
      <SnackbarComponent />
      <ProgressLine isMobile={isMobile} />
      {!auth.loggedIn && <SignIn isMobile={isMobile} />}
    </Box>
  );
}
