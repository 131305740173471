import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "sans-serif"'
  },
  palette: {
    primary: {
      main: '#0C62A7'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 768,
      lg: 1024,
      xl: 1536
    }
  }
});
