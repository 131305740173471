import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DraftUrl } from '../drafts/types';
import DeleteIcon from '../../assets/DeleteIcon';
import DeleteDialog from '../DeleteDialog';

type Props = {
  draftUrl: DraftUrl;
  isGenerated: boolean;
  // eslint-disable-next-line no-unused-vars
  handleDelete: (urlObjectId: string) => void;
};

const UrlCard = ({ draftUrl, isGenerated, handleDelete }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const url = new URL(draftUrl.url);
  const showConfirmModal = () => setShowModal(true);

  return (
    <Wrapper>
      <Image
        src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${url.host}`}
        alt={`${url.host}`}
      />
      <UrlTextWrapper>
        <UrlTitle>
          <Tooltip title={draftUrl.url} placement="top">
            <span>{draftUrl.url}</span>
          </Tooltip>
        </UrlTitle>
        {draftUrl.metadata && (
          <UrlDate>
            {draftUrl.metadata.source} | {draftUrl.metadata.date?.toLocaleDateString()}
          </UrlDate>
        )}
      </UrlTextWrapper>
      <CheckCircleIcon sx={{ width: 20, height: 20, color: '#1BB81F' }} />
      {!isGenerated && draftUrl.url !== '' && (
        <IconButton onClick={showConfirmModal} size="small" disabled={isGenerated}>
          <DeleteIcon />
        </IconButton>
      )}
      {showModal && (
        <DeleteDialog
          handleDelete={() => {
            handleDelete(draftUrl.id);
            setShowModal(false);
          }}
          handleCloseDialog={() => setShowModal(false)}
          title="Are you sure want to delete this url?"
          body="If you delete the url you can’t recover it."
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid #eaecf4;
  padding: 8px;
  width: 100%;
  min-width: 0;
`;

const UrlTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  padding-left: 8px;
  width: 100%;
  min-width: 0;
`;

const UrlTitle = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
`;

const UrlDate = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 11px;
  text-align: left;
  color: #787a7d;
`;

const Image = styled('img')`
  width: 20px;
  height: 20px;
`;

export default UrlCard;
