import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import DocumentsUpload from '../upload/DocumentsUpload';
import { MOBILE_BREAKPOINT } from '../../constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@emotion/styled';
import { DraftWrapper } from './constants';
import DraftActions from './DraftActions';
import DraftFeedback from './DraftFeedback';
import { getApiClient, logOutIfUnauthorized } from '../../utils';
import { AxiosResponse } from 'axios';
import { Draft, DraftVersionFeedback } from './types';
import DraftProgressPreview from './DraftProgressPreview';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDraft } from '../../store/draftsSlice';
import { setSnackbar } from '../../store/snackbarSlice';
import { RootState } from '../../store/store';
import { fetchDraft } from './utils';
import { setLoading } from '../../store/loadingSlice';

export default function DraftPreview() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT);
  const auth = useSelector((state: RootState) => state.auth);
  const draft = useSelector((state: RootState) => state.drafts.currentDraft);
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id && auth.loggedIn) {
      loadDraft();
    }
  }, [id, auth.loggedIn]);

  const loadDraft = () => {
    dispatch(setLoading(true));
    const draftResponse = fetchDraft(id!);
    if (draftResponse) {
      draftResponse
        .then((response: AxiosResponse<Draft>) => {
          dispatch(setCurrentDraft(response.data));
          dispatch(setLoading(false));
          if (previewRef.current) {
            (previewRef.current as HTMLDivElement).scrollTo({ top: 0 });
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (logOutIfUnauthorized(err?.response)) {
            return;
          }
          dispatch(
            setSnackbar({
              open: true,
              message: 'Draft not found',
              severity: 'error'
            })
          );
          navigate('/');
        });
    } else {
      dispatch(setLoading(false));
      dispatch(
        setSnackbar({
          open: true,
          message: 'Error loading draft.',
          severity: 'error'
        })
      );
    }
  };

  const handleFeedbackUpdate = (feedback: DraftVersionFeedback | undefined, versionId: string) => {
    const apiClient = getApiClient();
    if (draft && apiClient && feedback) {
      dispatch(setLoading(true));
      apiClient
        .post(`/api/client-catch/drafts/${draft?.id}/versions/${versionId}/feedback/`, feedback)
        .then(() => {
          dispatch(setLoading(false));
          dispatch(
            setSnackbar({
              open: true,
              message: 'Thank you for your feedback.',
              severity: 'success'
            })
          );
          loadDraft();
        })
        .catch((err) => {
          dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
          dispatch(setLoading(false));
        });
    }
  };

  const firstVersion = draft?.versions[0];

  return (
    <DraftWrapper mobile={isMobile ? 1 : 0}>
      {firstVersion && firstVersion.status === 'done' ? (
        <PreviewWrapper>
          <PreviewTitle>
            <Typography variant="h6">Preview</Typography>
            {draft && firstVersion && <DraftActions version={firstVersion} />}
          </PreviewTitle>
          <Divider />
          <Box
            ref={previewRef}
            sx={{ flexGrow: 1, overflowY: 'auto', textAlign: 'left', padding: 8 }}
            dangerouslySetInnerHTML={{ __html: firstVersion.result_html }}
          />
          {firstVersion && (
            <DraftFeedback
              isMobile={isMobile}
              feedback={firstVersion.feedback}
              setFeedback={handleFeedbackUpdate}
              versionId={firstVersion.id}
            />
          )}
        </PreviewWrapper>
      ) : (
        <DraftProgressPreview
          isLoading={!!firstVersion}
          showError={!!firstVersion && firstVersion.status === 'error'}
          isMobile={isMobile}
        />
      )}
      {!isMobile && <DocumentsUpload draft={draft} refreshDraft={loadDraft} />}
    </DraftWrapper>
  );
}

const PreviewWrapper = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const PreviewTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`;
