import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  color?: string;
};

export default function DeleteIcon({ color }: Props) {
  const strokeColor = color || '#E93D3D';

  return (
    <SvgIcon>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_367_10762)">
          <path
            d="M3.83325 5.8335H17.1666"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.83325 9.1665V14.1665"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.1667 9.1665V14.1665"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.66675 5.8335L5.50008 15.8335C5.50008 16.2755 5.67568 16.6994 5.98824 17.012C6.3008 17.3246 6.72472 17.5002 7.16675 17.5002H13.8334C14.2754 17.5002 14.6994 17.3246 15.0119 17.012C15.3245 16.6994 15.5001 16.2755 15.5001 15.8335L16.3334 5.8335"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 5.83333V3.33333C8 3.11232 8.0878 2.90036 8.24408 2.74408C8.40036 2.5878 8.61232 2.5 8.83333 2.5H12.1667C12.3877 2.5 12.5996 2.5878 12.7559 2.74408C12.9122 2.90036 13 3.11232 13 3.33333V5.83333"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_367_10762">
            <rect width="20" height="20" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
