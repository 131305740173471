import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import './App.css';
import Layout from './pages/Layout';
import DraftPreview from './components/drafts/DraftPreview';
import DraftEmptyPreview from './components/drafts/DraftEmptyPreview';
import { useDispatch } from 'react-redux';
import { setUser } from './store/authSlice';
import { AUTH_COOKIE_NAME } from './constants';
import { AppDispatch } from './store/store';

type Token = { name: string; email: string };

export default function App() {
  const dispatch: AppDispatch = useDispatch();
  const [cookie] = useCookies([AUTH_COOKIE_NAME]);

  const authenticate = () => {
    const token = cookie[AUTH_COOKIE_NAME];
    if (token) {
      // TODO Verify token and retrieve user data
      const decoded: Token = jwtDecode(token);
      dispatch(
        setUser({
          name: decoded.name,
          email: decoded.email,
          token
        })
      );
    } else {
      dispatch(setUser(undefined));
    }
  };

  useEffect(() => {
    authenticate();
  }, [cookie]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<DraftEmptyPreview />} />
        <Route path="/preview/:id" element={<DraftPreview />} />
        <Route path="*" element={<DraftEmptyPreview />} />
      </Route>
    </Routes>
  );
}
