import React, { FC } from 'react';
import { LinearProgress, Box } from '@mui/material';
import styled from '@emotion/styled';
import FileData from './FileData';

type Props = {
  // eslint-disable-next-line no-unused-vars
  handleRemove: (fileId: string, draftId: string) => void;
  totalSize?: number;
  uploadedSize?: number;
  fileName: string;
  fileType: string;
  inProgress: boolean;
  draftId: string;
  fileId: string;
  isGenerated: boolean;
};

const FileUpload: FC<Props> = ({
  fileName,
  fileType,
  handleRemove,
  totalSize,
  uploadedSize,
  inProgress,
  draftId,
  fileId,
  isGenerated
}) => {
  const showingProgress = inProgress && (uploadedSize ?? 0) < (totalSize ?? 0);
  return (
    <FileCard>
      <FileData
        fileName={fileName}
        inProgress={showingProgress}
        totalSize={totalSize}
        uploadedSize={uploadedSize}
        fileId={fileId}
        draftId={draftId}
        fileType={fileType}
        handleRemove={handleRemove}
        isGenerated={isGenerated}
      />
      {showingProgress && (
        <FileProgress>
          <LinearProgress variant="determinate" value={(uploadedSize! / totalSize!) * 100} />
        </FileProgress>
      )}
    </FileCard>
  );
};

const FileCard = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #eaecf4;
  width: calc(100% - 22px);
`;

const FileProgress = styled(Box)`
  height: auto;
`;

export default FileUpload;
