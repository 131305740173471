import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { Close, ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import { INITIAL_FEEDBACK, negativeFeedbackTags, positiveFeedbackTags } from './constants';
import { DraftVersionFeedback } from './types';

type Props = {
  isMobile: boolean;
  feedback: DraftVersionFeedback | undefined;
  // eslint-disable-next-line no-unused-vars
  setFeedback: (feedback: DraftVersionFeedback | undefined, versionId: string) => void;
  versionId: string;
};

export default function DraftFeedback({ isMobile, feedback, setFeedback, versionId }: Props) {
  const [showFeedback, setShowFeedback] = useState(false);
  const [additionalFeedback, setAdditionalFeedback] = useState(feedback?.additional ?? '');
  const [selectedFeedbackTags, setSelectedFeedbackTags] = useState<string[]>(
    feedback?.tags.split(',') ?? []
  );

  useEffect(() => {
    if (showFeedback) {
      setShowFeedback(false);
    }
    setAdditionalFeedback(feedback?.additional ?? '');
    setSelectedFeedbackTags(feedback?.tags.split(',') ?? []);
  }, [feedback]);

  const handleFeedbackSubmit = () => {
    setFeedback(
      {
        ...INITIAL_FEEDBACK,
        tags: selectedFeedbackTags.join(','),
        additional: additionalFeedback
      },
      versionId
    );

    setShowFeedback(false);
  };

  const handleThumbDown = () => {
    if (feedback && feedback.positive) {
      setSelectedFeedbackTags([]);
      return;
    }
    setShowFeedback(true);
  };

  const handleThumbUp = () => {
    if (!feedback) {
      setFeedback(
        {
          ...INITIAL_FEEDBACK,
          positive: true
        },
        versionId
      );
    }
  };

  const handleClickTag = (tag: string) => {
    if (feedback) {
      return;
    }
    if (selectedFeedbackTags.includes(tag)) {
      setSelectedFeedbackTags(selectedFeedbackTags.filter((t) => t !== tag));
    } else {
      setSelectedFeedbackTags([...selectedFeedbackTags, tag]);
    }
  };

  const tags = feedback?.positive ? positiveFeedbackTags : negativeFeedbackTags;

  return (
    <>
      <FeedbackWrapper mobile={isMobile ? 1 : 0}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color="#0C62A7" fontSize={14} fontWeight={500}>
            Give feedback
          </Typography>
          <FeedbackIconButton
            feedback={feedback ? (feedback.positive ? 1 : -1) : 0}
            direction="up"
            onClick={handleThumbUp}
            sx={{ ml: 1 }}
          >
            <ThumbUpAltOutlined
              color="primary"
              sx={{ fontSize: 16, fill: feedback && feedback.positive ? '#fff' : '' }}
            />
          </FeedbackIconButton>
          <FeedbackIconButton
            feedback={feedback ? (feedback.positive ? 1 : -1) : 0}
            direction="down"
            onClick={handleThumbDown}
            sx={{ ml: 1 }}
          >
            <ThumbDownAltOutlined
              color="primary"
              sx={{ fontSize: 16, fill: feedback && !feedback.positive ? '#fff' : '' }}
            />
          </FeedbackIconButton>
        </Box>
      </FeedbackWrapper>
      {showFeedback && (
        <FeedbackWrapper mobile={isMobile ? 1 : 0} flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize={16} fontWeight={500}>
              Why did you choose this rating?
            </Typography>
            <IconButton onClick={() => setShowFeedback(false)}>
              <Close fontSize="small" sx={{ fill: '#787A7D' }} />
            </IconButton>
          </Box>
          <Box display="flex" flexWrap="wrap" marginTop={1}>
            {tags.map((tag) => (
              <FeedbackTag
                key={tag}
                selected={selectedFeedbackTags.includes(tag) ? 1 : 0}
                onClick={() => handleClickTag(tag)}
              >
                {tag}
              </FeedbackTag>
            ))}
          </Box>
          <TextField
            multiline
            size="small"
            value={additionalFeedback}
            disabled={!!feedback}
            onChange={(ev) => setAdditionalFeedback(ev.target.value)}
            placeholder="Provide additional feedback"
            sx={{ backgroundColor: '#fff', mt: 1 }}
          />
          <Button
            sx={{ alignSelf: isMobile ? 'start' : 'end', textTransform: 'none' }}
            disabled={
              !!feedback || (additionalFeedback === '' && selectedFeedbackTags.length === 0)
            }
            onClick={handleFeedbackSubmit}
          >
            Submit
          </Button>
        </FeedbackWrapper>
      )}
    </>
  );
}

type FeedbackWrapperProps = {
  mobile: number;
};

const FeedbackWrapper = styled(Box)`
  display: flex;
  justify-content: ${(props: FeedbackWrapperProps) =>
    props.mobile === 1 ? 'flex-start' : 'flex-end'};
  background-color: #f5fafe;
  padding: 9px 16px;
  margin: 8px;
  border-radius: 5px;
`;

type FeedbackIconProps = {
  feedback: number;
  direction: 'up' | 'down';
};

const FeedbackIconButton = styled(IconButton)`
  background-color: ${(props: FeedbackIconProps) =>
    (props.feedback > 0 && props.direction === 'up') ||
    (props.feedback < 0 && props.direction === 'down')
      ? '#0C62A7'
      : '#fff'};
  border-radius: 0;
  &:hover {
    background-color: ${(props: FeedbackIconProps) =>
      (props.feedback > 0 && props.direction === 'up') ||
      (props.feedback < 0 && props.direction === 'down')
        ? '#0C62A7'
        : ''};
  }
`;

type TagProps = {
  selected: number;
};

const FeedbackTag = styled(Box)`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 12px;
  border: 1px #e3e9f2 solid;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background-color: ${(props: TagProps) => (props.selected === 1 ? '#0C62A7' : '')};
  color: ${(props: TagProps) => (props.selected === 1 ? '#FFF' : '#2A2A2A')};
  margin-right: 8px;
  margin-bottom: 4px;
`;
