import React from 'react';
import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { TOP_MENU_HEIGHT, TOP_MENU_MOBILE_HEIGHT } from '../constants';

type Props = {
  isMobile: boolean;
};

export default function ProgressLine({ isMobile }: Props) {
  const isLoading = useSelector<RootState, number>((state) => state.loading.isLoading);

  return isLoading > 0 ? (
    <LinearProgress
      sx={{
        position: 'absolute',
        top: isMobile ? TOP_MENU_MOBILE_HEIGHT : TOP_MENU_HEIGHT,
        width: '100vw',
        zIndex: (theme) => theme.zIndex.drawer + 100
      }}
    />
  ) : null;
}
