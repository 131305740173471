import React from 'react';
import { Box, Typography } from '@mui/material';
import GradientCircularProgress from '../../assets/GradientCircularProgress';
import FeatherIcon from '../../assets/FeatherIcon';
import { ReportGmailerrorred } from '@mui/icons-material';

type Props = {
  isLoading: boolean;
  showError: boolean;
  isMobile: boolean;
};

export default function DraftProgressPreview({ isLoading, showError, isMobile }: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      className="Wrapper"
      borderRadius={1}
      justifyContent="center"
      alignItems="center"
    >
      {showError ? (
        <ReportGmailerrorred sx={{ fontSize: 72, color: '#E93D3D' }} />
      ) : isLoading ? (
        <GradientCircularProgress sxProps={{ mt: 3 }} />
      ) : (
        <FeatherIcon sx={{ fontSize: 72 }} />
      )}
      <Typography variant="h5" fontWeight={700} marginTop={1}>
        {showError
          ? 'Error generating client alert.'
          : isLoading
            ? `We're analyzing the law and browsing our document library.`
            : `Let's create your custom Alert!`}
      </Typography>
      {showError ? (
        <Typography variant="body1" color="#787A7D" lineHeight="1.25" marginTop={1}>
          Create a new draft and try again.
        </Typography>
      ) : isLoading ? (
        <>
          <Typography variant="body1" color="#787A7D" lineHeight="1.25" marginTop={1}>
            This process will take 2-5 minutes.
          </Typography>
          <Typography variant="body1" color="#787A7D" lineHeight="1.25" marginTop={1}>
            Feel free to work in a different tab while we write your alert!
          </Typography>
        </>
      ) : (
        <Typography variant="body1" color="#787A7D" lineHeight="1.25" marginTop={1}>
          Choose your topic, sources and audience
          <br />
          in the {isMobile ? 'uploads' : 'right-hand'} panel
        </Typography>
      )}
    </Box>
  );
}
