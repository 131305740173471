import { createSlice } from '@reduxjs/toolkit';

type DrawerState = {
  open: boolean;
  position: 'left' | 'right';
  content: 'menu' | 'drafts' | 'upload';
};

type Action = {
  payload: DrawerState;
  type: string;
};

const initialDrawerState: DrawerState = {
  open: false,
  position: 'left',
  content: 'menu'
};

export const mobileDrawerSlice = createSlice({
  name: 'generate',
  initialState: {
    mobileDrawerState: initialDrawerState
  },
  reducers: {
    setMobileDrawerState: (state, action: Action) => {
      state.mobileDrawerState = action.payload;
    }
  }
});

export const { setMobileDrawerState } = mobileDrawerSlice.actions;

export default mobileDrawerSlice.reducer;
