import React, { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SIZES } from './constants';
import { Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import Spinner from '../../assets/Spinner';

// eslint-disable-next-line no-unused-vars
const getDisplayedSizeAndNotation: (value: number) => { size: number; notation: string } = (
  value
) => {
  try {
    let index = 0;
    const result = { size: value, notation: SIZES[index] };
    while (result.size > 1024) {
      result.size = parseFloat((result.size / 1024).toFixed(1));
      index++;
      result.notation = SIZES[index];
    }

    return result;
  } catch (err) {
    return { size: value, notation: SIZES[0] };
  }
};

type Props = {
  totalSize?: number;
  uploadedSize?: number;
  fileName: string;
  inProgress: boolean;
};

const FileText: FC<Props> = ({ fileName, totalSize, uploadedSize, inProgress }) => {
  const { size: convertedUploadedSize, notation: convertedUploadedNotation } =
    getDisplayedSizeAndNotation(uploadedSize || 0);
  const { size: convertedTotalSize, notation: convertedTotalNotation } =
    getDisplayedSizeAndNotation(totalSize || 0);

  return (
    <FileTextContainer>
      <FileName>{fileName}</FileName>
      <FileSizeContainer>
        {inProgress ? (
          <>
            <UploadText>
              {convertedUploadedSize} {convertedUploadedNotation} of {convertedTotalSize}{' '}
              {convertedTotalNotation}
            </UploadText>

            <ProgressSpinnerContainer>
              <Spinner />
              <UploadText sx={{ marginLeft: '10px' }}>Uploading...</UploadText>
            </ProgressSpinnerContainer>
          </>
        ) : (
          <>
            <CheckCircleIcon sx={{ width: 15, height: 15, color: '#1BB81F' }} />
            <UploadText>Completed</UploadText>
          </>
        )}
      </FileSizeContainer>
    </FileTextContainer>
  );
};

const FileTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  min-width: 0px;
  flex-grow: 1;
`;

const FileName = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: left;
`;

const UploadText = styled(Typography)`
  font-weight: 400;
  color: #787a7d;
  font-size: 12px;
`;

const FileSizeContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const ProgressSpinnerContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export default FileText;
