import { createSlice } from '@reduxjs/toolkit';

type Auth = {
  loggedIn: boolean;
  user: User | undefined;
};

type User = {
  name: string;
  email: string;
  token: string;
};

type SetUserAction = {
  type: string;
  payload: User | undefined;
};

const initialAuthState: Auth = {
  loggedIn: false,
  user: undefined
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setUser: (state, action: SetUserAction) => {
      state.loggedIn = !!action.payload;
      state.user = action.payload;
    }
  }
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;
