import { configureStore } from '@reduxjs/toolkit';
import mobileDrawerReducer from './mobileDrawerSlice';
import authReducer from './authSlice';
import snackbarReducer from './snackbarSlice';
import tabReducer from './tabSlice';
import draftsReducer from './draftsSlice';
import loadingReducer from './loadingSlice';

export const store = configureStore({
  reducer: {
    mobileDrawer: mobileDrawerReducer,
    auth: authReducer,
    snackbar: snackbarReducer,
    tab: tabReducer,
    drafts: draftsReducer,
    loading: loadingReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
