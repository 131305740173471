import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import { FC } from 'react';
import { UrlObject } from './types';
import { theme } from '../../theme';
import UrlInput from './UrlInput';
import { Draft, DraftListType } from '../drafts/types';
import { MAX_NUMBER_OF_FILES_OR_URLS, initialUrlObject } from './constants';
import { getApiClient, logOutIfUnauthorized } from '../../utils';
import { isValidUrl } from './utils';
import { createNewDraft } from '../drafts/utils';
import { useNavigate } from 'react-router-dom';
import { fetchDrafts } from '../drafts/utils';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setDrafts, setFilesOrUrlsUploaded, DraftFileOrUrlUploaded } from '../../store/draftsSlice';
import { setSnackbar } from '../../store/snackbarSlice';
import { setLoading } from '../../store/loadingSlice';
import { RootState } from '../../store/store';
import { getMaxAmountErrorMessage } from './utils';

type Props = {
  // eslint-disable-next-line no-unused-vars
  draft: Draft | undefined;
  refreshDraft: () => void;
  isGenerated: boolean;
};

const UrlsZone: FC<Props> = ({ draft, refreshDraft, isGenerated }) => {
  const [showNewUrl, setShowNewUrl] = useState(false);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newDraft = useSelector<RootState, DraftListType | undefined>((state) => {
    return state.drafts.newDraft;
  });
  const fileOrUrlsUploaded = useSelector<RootState, DraftFileOrUrlUploaded | undefined>(
    (state) => state.drafts.fileOrUrlsUploaded
  );

  useEffect(() => {
    if (draft) {
      if (fileOrUrlsUploaded?.urlsUploaded) {
        if (
          (draft?.files?.length || 0) +
            (draft?.urls.length || 0) +
            fileOrUrlsUploaded.filesUploaded.length +
            fileOrUrlsUploaded.urlsUploaded.length <=
          MAX_NUMBER_OF_FILES_OR_URLS
        ) {
          handleUrlChange(fileOrUrlsUploaded?.urlsUploaded?.[0]);
        } else {
          setError(getMaxAmountErrorMessage());
        }

        dispatch(setFilesOrUrlsUploaded(undefined));
      } else {
        setError('');
      }
    } else {
      setShowNewUrl(true);
    }
  }, [JSON.stringify(draft)]);

  const handleUrlChange = async (urlObject: UrlObject) => {
    if (isValidUrl(urlObject.draftUrl.url)) {
      const apiClient = getApiClient();
      let currentDraftId = draft?.id;
      if (apiClient) {
        if (!draft) {
          try {
            if (!newDraft) {
              dispatch(setLoading(true));
              const createdNewDraft = await createNewDraft();
              if (logOutIfUnauthorized(createdNewDraft)) {
                return;
              }
              currentDraftId = createdNewDraft!.data.id;
              dispatch(setLoading(false));

              dispatch(setLoading(true));
              const response = fetchDrafts();
              if (response) {
                response
                  .then((res: AxiosResponse<DraftListType[]>) => {
                    dispatch(setDrafts(res.data));
                    dispatch(setLoading(false));
                  })
                  .catch((err) => {
                    dispatch(setLoading(false));
                    if (logOutIfUnauthorized(err?.response)) {
                      return;
                    }
                    dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
                  });
              }
            } else {
              currentDraftId = newDraft.id;
              dispatch(
                setFilesOrUrlsUploaded({
                  filesUploaded: [],
                  urlsUploaded: [urlObject]
                })
              );

              navigate(`/preview/${currentDraftId}`);
              return;
            }

            navigate(`/preview/${currentDraftId}`);
          } catch (err) {
            console.log(err);
            dispatch(setLoading(false));
          }
        }
        dispatch(setLoading(true));
        apiClient
          .post(`/api/client-catch/drafts/${currentDraftId}/urls/`, {
            url: urlObject.draftUrl.url
          })
          .then(() => {
            dispatch(setLoading(false));
            refreshDraft();
            setShowNewUrl(false);
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
          });
      }
    }
  };

  const handleAdd = () => {
    const numberOfUrls = draft?.urls?.length || 0;
    const numberOfFiles = draft?.files.length || 0;

    if (numberOfUrls + numberOfFiles < MAX_NUMBER_OF_FILES_OR_URLS) {
      setShowNewUrl(true);
    }
  };

  const handleDelete = (urlObjectId: string) => {
    if (!urlObjectId) {
      setShowNewUrl(false);
      return;
    }

    const apiClient = getApiClient();
    if (apiClient) {
      dispatch(setLoading(true));
      apiClient
        .delete(`/api/client-catch/drafts/${draft?.id}/urls/${urlObjectId}/`)
        .then(() => {
          dispatch(setLoading(false));
          refreshDraft();
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
        });
    }
  };

  const isMaxAmountReached = () => {
    if (draft && draft?.urls && draft?.files) {
      return draft?.urls.length + draft?.files.length >= MAX_NUMBER_OF_FILES_OR_URLS;
    }

    return false;
  };

  return (
    <Wrapper>
      {draft?.urls &&
        draft?.urls?.length > 0 &&
        draft.urls.map((draftUrl) => {
          const urlObject: UrlObject = {
            draftUrl,
            isValid: true
          };

          return (
            <UrlInput
              key={draftUrl.id}
              handleUpdate={handleUrlChange}
              handleDelete={handleDelete}
              urlObject={urlObject}
              isGenerated={isGenerated}
              isMaxAmountReached={isMaxAmountReached()}
            />
          );
        })}

      {((draft?.urls && draft.urls.length === 0) || showNewUrl) && (
        <>
          <UrlInput
            handleUpdate={handleUrlChange}
            handleDelete={handleDelete}
            urlObject={initialUrlObject}
            isGenerated={isGenerated}
            isMaxAmountReached={isMaxAmountReached()}
          />
          {error && (
            <Typography color="#E93D3D" fontSize="12px" alignSelf="flex-start" paddingLeft="4px">
              {error}
            </Typography>
          )}
        </>
      )}
      <AddButton
        variant="text"
        startIcon={<AddIcon />}
        onClick={handleAdd}
        disabled={
          (draft?.urls &&
            draft?.files &&
            draft?.urls.length + draft?.files.length >=
              MAX_NUMBER_OF_FILES_OR_URLS - (showNewUrl ? 1 : 0)) ||
          isGenerated ||
          !draft
        }
      >
        Add
      </AddButton>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 8px;
`;

const AddButton = styled(Button)`
  text-transform: none;
  color: ${theme.palette.primary.main};
  font-size: 14px;
  align-self: flex-start;
`;

export default UrlsZone;
