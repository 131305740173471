import { Avatar } from '@mui/material';
import React from 'react';

export const getInitials = (name: string) => {
  const names = name.split(' ');
  if (names.length === 1) {
    return name[0].toUpperCase();
  }
  return `${names[0][0].toUpperCase()}${names[names.length - 1][0].toUpperCase()}`;
};

export const getAvatar = (name: string, isMobile: boolean) => (
  <Avatar
    sx={{
      backgroundColor: (theme) => theme.palette.primary.main,
      cursor: 'pointer',
      mr: isMobile ? 1 : 0,
      fontSize: isMobile ? 16 : '',
      height: isMobile ? 32 : 40,
      width: isMobile ? 32 : 40
    }}
  >
    {getInitials(name)}
  </Avatar>
);
