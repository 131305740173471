import React, {
  DragEvent,
  useRef,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  useState,
  useEffect
} from 'react';
import { Box, Typography } from '@mui/material';
import UploadIcon from '../../assets/UploadIcon';
import styled from '@emotion/styled';
import {
  ACCEPTED_FILES_TEXT,
  DRAG_AND_DROP_TEXT,
  ACCEPTED_FILE_TYPES,
  ACCEPTED_FILE_TYPE_EXTENSIONS,
  MAX_FILE_SIZE,
  MAX_NUMBER_OF_FILES_OR_URLS
} from './constants';
import { theme } from '../../theme';
import { FileType, FileUploadType } from './types';
import { Draft, DraftListType } from '../drafts/types';
import { getApiClient, logOutIfUnauthorized } from '../../utils';
import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import { createNewDraft } from '../drafts/utils';
import FileUpload from './FileUpload';
import { setSnackbar } from '../../store/snackbarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchDrafts } from '../drafts/utils';
import { DraftFileOrUrlUploaded, setDrafts, setFilesOrUrlsUploaded } from '../../store/draftsSlice';
import { setLoading } from '../../store/loadingSlice';
import { RootState } from '../../store/store';
import { getMaxAmountErrorMessage } from './utils';

type Props = {
  draft: Draft | undefined;
  refreshDraft: () => void;
  // eslint-disable-next-line no-unused-vars
  handleRemove: (fileId: string, draftId: string) => void;
  isGenerated: boolean;
};

const filterAcceptedFiles = (
  files: FileList,
  setFileError: Dispatch<SetStateAction<string>>
): File[] => {
  const acceptedFiles: File[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (ACCEPTED_FILE_TYPES.includes(file.type) && file.size < MAX_FILE_SIZE) {
      acceptedFiles.push(file);
    } else {
      setFileError('Accepted file types: docx, pdf, txt, Max. file size: 5 MB.');
    }
  }
  return acceptedFiles;
};

const UploadCard = ({ draft, refreshDraft, handleRemove, isGenerated }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string>('');
  const [currentFiles, setCurrentFiles] = useState<FileType[]>([]);
  const [processedFiles, setProcessedFiles] = useState<number>(0);
  const newDraft = useSelector<RootState, DraftListType | undefined>(
    (state) => state.drafts.newDraft
  );
  const fileOrUrlsUploaded = useSelector<RootState, DraftFileOrUrlUploaded | undefined>(
    (state) => state.drafts.fileOrUrlsUploaded
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (draft) {
      setCurrentFiles([]);
      if (fileOrUrlsUploaded?.filesUploaded) {
        if (
          (draft?.files?.length || 0) +
            (draft?.urls.length || 0) +
            fileOrUrlsUploaded.filesUploaded.length +
            fileOrUrlsUploaded.urlsUploaded.length <=
          MAX_NUMBER_OF_FILES_OR_URLS
        ) {
          uploadNewFiles(fileOrUrlsUploaded?.filesUploaded).catch((err) =>
            dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }))
          );
        } else if (fileOrUrlsUploaded?.filesUploaded.length > 0) {
          setFileError(getMaxAmountErrorMessage());
        }

        dispatch(setFilesOrUrlsUploaded(undefined));
      } else {
        setFileError('');
      }
    }
  }, [JSON.stringify(draft)]);

  useEffect(() => {
    if (processedFiles > 0 && processedFiles === currentFiles.length) {
      setProcessedFiles(0);
      refreshDraft();
    }
  }, [processedFiles]);

  const getUploadConfig = (fileId: string) => ({
    onUploadProgress: async function (progressEvent: AxiosProgressEvent) {
      setCurrentFiles((prevValue) =>
        prevValue.map((file) => {
          if (file.fileId === fileId) {
            return { ...file, uploadedSize: progressEvent.loaded };
          }

          return file;
        })
      );
    }
  });

  const isMaxFilesNotReached = (acceptedFiles: File[]) => {
    const numberOfUrls = draft?.urls?.length || 0;
    const numberOfFiles = draft?.files.length || 0;

    return numberOfUrls + numberOfFiles + acceptedFiles.length <= MAX_NUMBER_OF_FILES_OR_URLS;
  };

  const uploadNewFiles = async (acceptedFiles: File[]) => {
    const apiClient = getApiClient();
    let currentDraftId = draft?.id;
    if (apiClient) {
      if (!draft) {
        try {
          if (!newDraft) {
            dispatch(setLoading(true));
            const createdNewDraft = await createNewDraft();
            if (logOutIfUnauthorized(createdNewDraft)) {
              return;
            }
            currentDraftId = createdNewDraft!.data.id;
            dispatch(setLoading(false));

            dispatch(setLoading(true));
            const response = fetchDrafts();
            if (response) {
              response
                .then((res: AxiosResponse<DraftListType[]>) => {
                  dispatch(setDrafts(res.data));
                  dispatch(setLoading(false));
                })
                .catch((err) => {
                  dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
                  dispatch(setLoading(false));
                });
            } else {
              dispatch(setLoading(false));
            }
          } else {
            currentDraftId = newDraft.id;
            dispatch(
              setFilesOrUrlsUploaded({
                filesUploaded: acceptedFiles,
                urlsUploaded: []
              })
            );

            navigate(`/preview/${currentDraftId}`);
            return;
          }

          navigate(`/preview/${currentDraftId}`);
        } catch (err) {
          dispatch(setLoading(false));
          console.log(err);
        }
      }
      for (let file of acceptedFiles) {
        dispatch(setLoading(true));
        const res: FileUploadType = (
          await apiClient.post(`/api/client-catch/drafts/${currentDraftId}/files/`, {
            filename: file.name,
            file_type: file.type,
            size: file.size
          })
        ).data;
        dispatch(setLoading(false));
        setCurrentFiles((prevValue) => [
          ...prevValue,
          {
            fileId: res.file.id,
            fileName: file.name,
            fileType: file.type,
            uploadedSize: 0,
            totalSize: file.size
          }
        ]);
        const uploadData = res['upload_data'];
        const fileId = res['file'].id;

        const uploadFilePayload = new FormData();

        Object.entries(uploadData.fields).forEach(([key, value]) => {
          uploadFilePayload.append(key, value.toString());
        });

        uploadFilePayload.append('file', file);
        dispatch(setLoading(true));
        axios
          .post(uploadData.url, uploadFilePayload, getUploadConfig(fileId))
          .then(() =>
            apiClient
              .post(`/api/client-catch/drafts/${currentDraftId}/files/${fileId}/finalize/`)
              .then(() => {
                setProcessedFiles((prev) => prev + 1);
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
                dispatch(setLoading(false));
              })
          )
          .catch((err) => {
            dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
            dispatch(setLoading(false));
          });
      }
    }
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.persist();

    if (isGenerated) {
      return;
    }

    setFileError('');

    const acceptedFiles = filterAcceptedFiles(event.dataTransfer.files, setFileError);

    if (acceptedFiles.length > 0 && isMaxFilesNotReached(acceptedFiles)) {
      uploadNewFiles(acceptedFiles).catch((err) =>
        dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }))
      );
    } else if (acceptedFiles.length > 0) {
      setFileError(getMaxAmountErrorMessage());
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setFileError('');
    if (files) {
      const acceptedFiles = filterAcceptedFiles(files, setFileError);
      if (acceptedFiles.length > 0 && isMaxFilesNotReached(acceptedFiles)) {
        uploadNewFiles(acceptedFiles).catch((err) =>
          dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }))
        );
      } else if (acceptedFiles.length > 0) {
        setFileError(getMaxAmountErrorMessage());
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" gap="4px">
      {!isGenerated && (
        <Card
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onClick={handleClick}
        >
          <UploadIcon />
          <DragAndDropText>{DRAG_AND_DROP_TEXT}</DragAndDropText>
          <FileConstraintsText>{ACCEPTED_FILES_TEXT}</FileConstraintsText>
          <input
            data-testid="upload-input"
            type="file"
            multiple={true}
            hidden
            ref={inputRef}
            accept={ACCEPTED_FILE_TYPE_EXTENSIONS.join(',')}
            onChange={handleInputChange}
            disabled={isGenerated}
          />
        </Card>
      )}
      {isGenerated && (draft?.files?.length || 0) === 0 && (
        <Typography fontSize="14px" color="gray" fontStyle="italic">
          No files added.
        </Typography>
      )}
      {fileError && (
        <Typography color="#E93D3D" fontSize="12px">
          {fileError}
        </Typography>
      )}
      {draft &&
        currentFiles &&
        currentFiles.map((file) => (
          <FileUpload
            key={file.fileId}
            fileId={file.fileId}
            fileName={file.fileName}
            fileType={file.fileType}
            totalSize={file.totalSize}
            uploadedSize={file.uploadedSize}
            inProgress={true}
            draftId={draft.id}
            handleRemove={handleRemove}
            isGenerated={isGenerated}
          />
        ))}
      {draft?.files &&
        draft?.files.length > 0 &&
        draft.files.map((file) => {
          return (
            <FileUpload
              key={file.id}
              fileName={file.filename}
              inProgress={false}
              fileId={file.id}
              draftId={draft.id}
              fileType={file.file_type}
              handleRemove={handleRemove}
              isGenerated={isGenerated}
            />
          );
        })}
    </Box>
  );
};

const Card = styled(Box)`
  border: 1.5px dashed #6fbfff;
  background-color: rgba(245, 250, 254, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: calc(100% - 51px);
`;

const DragAndDropText = styled(Typography)`
  color: ${theme.palette.primary.main};
  font-weight: 500;
  font-size: 16px;
`;

const FileConstraintsText = styled(Typography)`
  color: #787a7d;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
`;

export default UploadCard;
