import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import { Box, Menu, MenuItem, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { setMobileDrawerState } from '../../store/mobileDrawerSlice';
import { DraftListType } from './types';

type DraftProps = {
  draft: DraftListType;
  // eslint-disable-next-line no-unused-vars
  confirmDraftDelete: (draftId: string) => void;
  isMobile: boolean;
};

export default function DraftListItem({ draft, confirmDraftDelete, isMobile }: DraftProps) {
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isHovered, setIsHovered] = useState(false);
  const moreRef = useRef<SVGSVGElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<SVGSVGElement | HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsHovered(false);
  };

  const handlePreview = () => {
    setIsHovered(false);
    setAnchorEl(null);
    navigate(`/preview/${draft.id}`);
    if (isMobile) {
      dispatch(
        setMobileDrawerState({
          open: false,
          position: 'left',
          content: 'drafts'
        })
      );
    }
  };

  const handleDelete = () => {
    setIsHovered(false);
    setAnchorEl(null);
    confirmDraftDelete(draft.id);
  };

  const handleOnClick = (ev: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      handleClick(ev);
    } else {
      handlePreview();
    }
  };

  return (
    <DraftItem
      onClick={handleOnClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setAnchorEl(null);
      }}
      hovered={isHovered ? 1 : 0}
      selected={id === draft.id ? 1 : 0}
    >
      <Tooltip title={draft.title ?? 'New Draft'} placement="right-end">
        <DraftTitle>{draft.title ?? 'New Draft'}</DraftTitle>
      </Tooltip>
      {isHovered && (
        <>
          <MoreVert sx={{ fontSize: 14, zIndex: 100 }} onClick={handleClick} ref={moreRef} />
          <CustomMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            disableAutoFocusItem
          >
            <MenuItem
              dense
              selected={false}
              sx={{ width: 128, height: 29 }}
              onClick={handlePreview}
            >
              Preview
            </MenuItem>
            <MenuItem dense sx={{ width: 128, height: 29, margin: 0 }} onClick={handleDelete}>
              Delete
            </MenuItem>
          </CustomMenu>
        </>
      )}
    </DraftItem>
  );
}

type DraftItemProps = {
  hovered: number;
  selected: number;
};

const DraftItem = styled(Box)`
  margin: 4px 8px 0 8px;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props: DraftItemProps) =>
    props.hovered === 1 ? '#EFF8FF' : props.selected === 1 ? '#E6E6E6' : 'transparent'};
`;

const DraftTitle = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  text-align: start;
  font-size: 14px;
`;

export const CustomMenu = styled(Menu)`
  & .MuiPaper-root {
    margin-top: 8px;
  }
  & .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  & .MuiMenuItem-root:hover {
    background-color: #eff8ff;
  }
`;
