import React from 'react';
import { Button, ButtonProps, Link } from '@mui/material';
import styled from '@emotion/styled';

export const LinksGroup = () => (
  <>
    <CustomLink underline="none" href="https://briefcatch.com" target="_blank">
      Home
    </CustomLink>
    <CustomLink underline="none" href="https://support.briefcatch.com/home" target="_blank">
      Support
    </CustomLink>
  </>
);

type ButtonsProps = {
  isMobile: boolean;
};

export const LoginButtons = ({ isMobile }: ButtonsProps) => (
  <>
    <CustomButton
      variant="outlined"
      isMobile={isMobile}
      href={`https://app.briefcatch.com/login/?redirect_to=${window.location.href}`}
    >
      Sign In
    </CustomButton>
    <CustomButton
      variant="outlined"
      isMobile={isMobile}
      href="https://meetings.hubspot.com/kara-furstenburg?uuid=1c2b4dea-f075-47e4-bf9f-c7f093778c2a"
    >
      Book a Demo
    </CustomButton>
    <CustomButton
      variant="contained"
      isMobile={isMobile}
      ignoreMobile={true}
      href="https://info.briefcatch.com/en-us/free-trial-signup"
    >
      Try for Free
    </CustomButton>
  </>
);

type ButtonOptions = ButtonsProps & { ignoreMobile?: boolean };

const CustomButton = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }> & ButtonOptions
) => (
  <Button
    variant={props.isMobile && props.variant !== 'contained' ? undefined : props.variant}
    size="small"
    href={props.href}
    style={{ width: props.isMobile && props.ignoreMobile ? 186 : '' }}
    sx={{
      margin: props.isMobile ? '0 12px' : '',
      lineHeight: props.isMobile && !props.ignoreMobile ? 1.25 : '',
      padding: props.isMobile && !props.ignoreMobile ? 0 : '',
      justifyContent: props.isMobile && !props.ignoreMobile ? 'flex-start' : 'center',
      mr: (props.isMobile && !props.ignoreMobile) || props.variant === 'contained' ? 0 : 1,
      textTransform: 'none',
      fontSize: props.isMobile ? 14 : '',
      color: (theme) =>
        props.ignoreMobile ? '' : props.isMobile ? theme.palette.text.primary : '#0202028A',
      borderColor: !props.isMobile && !props.ignoreMobile ? '#E0E3EA' : ''
    }}
  >
    {props.children}
  </Button>
);

const CustomLink = styled(Link)`
  margin: 0 12px;
  font-size: 14px;
  font-weight: 500;
  color: #2a2a2a;
`;
