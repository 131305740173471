import { getApiClient } from '../../utils';
import { DraftListType, Draft } from './types';
import { AxiosResponse } from 'axios';

export const createNewDraft = (): Promise<AxiosResponse<DraftListType>> | undefined => {
  const apiClient = getApiClient();
  if (!apiClient) {
    return undefined;
  }
  return apiClient.post('/api/client-catch/drafts/');
};

export const fetchDraft = (draftId: string): Promise<AxiosResponse<Draft>> | undefined => {
  const apiClient = getApiClient();
  if (!apiClient) {
    return undefined;
  }
  return apiClient.get(`/api/client-catch/drafts/${draftId}/`);
};

export const fetchDrafts = (): Promise<AxiosResponse<DraftListType[]>> | undefined => {
  const apiClient = getApiClient();
  if (!apiClient) {
    return undefined;
  }

  return apiClient.get(`/api/client-catch/drafts/`);
};
