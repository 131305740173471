import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '../../assets/DeleteIcon';
import PDFDocument from '../../assets/PDFDocument';
import FileText from './FileText';
import DeleteDialog from '../DeleteDialog';

type Props = {
  // eslint-disable-next-line no-unused-vars
  handleRemove: (fileId: string, draftId: string) => void;
  totalSize?: number;
  uploadedSize?: number;
  fileName: string;
  fileType: string;
  inProgress: boolean;
  draftId: string;
  fileId: string;
  isGenerated: boolean;
};

const FileData: FC<Props> = ({
  fileName,
  fileType,
  handleRemove,
  totalSize,
  uploadedSize,
  inProgress,
  draftId,
  fileId,
  isGenerated
}) => {
  const [fileToDelete, setFileToDelete] = useState<boolean>();

  const confirmFileDelete = () => {
    setFileToDelete(true);
  };

  // eslint-disable-next-line no-unused-vars
  const getFileImage = (fileType: string) => {
    return <FileImage />;
  };

  return (
    <FileDataWrapper>
      {getFileImage(fileType)}
      <FileText
        fileName={fileName}
        inProgress={inProgress}
        totalSize={totalSize}
        uploadedSize={uploadedSize}
      />
      {!isGenerated && (
        <IconButton onClick={confirmFileDelete} disabled={isGenerated}>
          <DeleteIcon />
        </IconButton>
      )}
      {fileToDelete && (
        <DeleteDialog
          handleDelete={() => handleRemove(fileId, draftId)}
          handleCloseDialog={() => setFileToDelete(false)}
          title="Are you sure want to delete this file?"
          body="If you delete the file you can’t recover it."
        />
      )}
    </FileDataWrapper>
  );
};

const FileDataWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const FileImage = styled(PDFDocument)``;

export default FileData;
