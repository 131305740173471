import React from 'react';
import { Box } from '@mui/material';
import { TOP_MENU_MOBILE_HEIGHT } from '../../constants';
import { LinksGroup, LoginButtons } from './CustomLinksButtons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export default function MobileLeftMenu() {
  const auth = useSelector((state: RootState) => state.auth);

  return (
    <Box
      width="210px"
      height={`calc(100vh - ${TOP_MENU_MOBILE_HEIGHT}px)`}
      marginTop={`${TOP_MENU_MOBILE_HEIGHT}px`}
      display="flex"
      flexDirection="column"
      rowGap={3}
      alignItems="flex-start"
      paddingTop={2}
      className="Wrapper"
    >
      <LinksGroup />
      {!auth.loggedIn && <LoginButtons isMobile={true} />}
    </Box>
  );
}
