import React from 'react';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  DOCUMENT_PREVIEW_MOBILE_STYLE,
  DOCUMENT_PREVIEW_STYLE,
  MOBILE_BREAKPOINT
} from '../../constants';
import DocumentsUpload from '../upload/DocumentsUpload';
import DraftProgressPreview from './DraftProgressPreview';

export default function DraftEmptyPreview() {
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT);

  return (
    <Box display="flex" style={isMobile ? DOCUMENT_PREVIEW_MOBILE_STYLE : DOCUMENT_PREVIEW_STYLE}>
      <DraftProgressPreview isLoading={false} showError={false} isMobile={isMobile} />
      {!isMobile && <DocumentsUpload draft={undefined} refreshDraft={() => {}} />}
    </Box>
  );
}
