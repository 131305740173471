export const SIZES: string[] = ['B', 'KB', 'MB'];
export const MAX_FILE_SIZE: number = 5 * 1024 * 1024; // 5MB
export const MAX_NUMBER_OF_FILES_OR_URLS: number = 1;
export const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
export const ACCEPTED_FILE_TYPE_EXTENSIONS = ['.pdf', '.txt', '.docx', '.doc'];
export type UPLOAD_SECTION_TEXT_TYPE = {
  sectionText: string;
  placeholderText: string;
};
export const UPLOAD_SOURCE_FILE_TEXT: UPLOAD_SECTION_TEXT_TYPE = {
  sectionText: 'Upload source files',
  placeholderText: ''
};
export const COMPARISON_SOURCES_TEXT: UPLOAD_SECTION_TEXT_TYPE = {
  sectionText: 'Comparison sources',
  placeholderText: ''
};
export const YOUR_TARGER_AUDIENCE_TEXT: UPLOAD_SECTION_TEXT_TYPE = {
  sectionText: 'Target Audience (Optional)',
  placeholderText:
    'Please describe your expected readers, such as "Broker dealers worried about whether the new rule will inhibit client interactions".'
};
export const OTHER_INSTRUCTIONS_TEXT: UPLOAD_SECTION_TEXT_TYPE = {
  sectionText: 'Other Guidance (optional)',
  placeholderText: 'Specify any points to include or exclude.'
};

export const DRAG_AND_DROP_TEXT = 'Drag & drop or choose file';
export const ACCEPTED_FILES_TEXT = 'Accepted file types: docx, pdf, txt, Max. file size: 5 MB.';
export const FILE_TAB = 0;
export const URL_TAB = 1;

export const initialUrlObject = {
  draftUrl: {
    id: '',
    url: '',
    metadata: undefined
  },
  isValid: false
};
