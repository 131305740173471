import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export default function Spinner() {
  const circles = [...Array(6)].map((_, index) => {
    return (
      <SpinnerCircleRotated key={index}>
        <SpinnerAfter style={{ background: '#0C62A7' }}></SpinnerAfter>
      </SpinnerCircleRotated>
    );
  });

  return <SpinnerWrapper>{circles}</SpinnerWrapper>;
}

const spinAnimation = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const SpinnerWrapper = styled('div')`
  display: inline-block;
  position: relative;
  width: 7px;
  height: 7px;
  margin-bottom: 7px;
`;
const SpinnerCircle = styled.div`
  transform-origin: 7px 7px;
  animation: ${spinAnimation} 1.2s linear infinite;
`;

const SpinnerAfter = styled.div`
  content: ' ';
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 2px;
  height: 5px;
  border-radius: 20%;
  background: #fff;
`;

const SpinnerCircleRotated = styled(SpinnerCircle)`
  &:nth-of-type(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  &:nth-of-type(2) {
    transform: rotate(60deg);
    animation-delay: -1s;
  }
  &:nth-of-type(3) {
    transform: rotate(120deg);
    animation-delay: -0.9s;
  }
  &:nth-of-type(4) {
    transform: rotate(180deg);
    animation-delay: -0.8s;
  }
  &:nth-of-type(5) {
    transform: rotate(240deg);
    animation-delay: -0.7s;
  }
  &:nth-of-type(6) {
    transform: rotate(300deg);
    animation-delay: -0.6s;
  }
`;
