import { createSlice } from '@reduxjs/toolkit';
import { Draft, DraftListType } from '../components/drafts/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { UrlObject } from '../components/upload/types';

export type DraftUploadFields = {
  target_audience: string;
  other_instructions: string;
};

export type DraftFileOrUrlUploaded = {
  filesUploaded: File[];
  urlsUploaded: UrlObject[];
};

type DraftsState = {
  userDrafts: DraftListType[];
  newDraft: DraftListType | undefined;
  currentDraft: Draft | undefined;
  draftInProgress: string | undefined;
  newDraftFields: DraftUploadFields;
  fileOrUrlsUploaded: DraftFileOrUrlUploaded | undefined;
};

type SetCurrentDraftAction = {
  type: string;
  payload: Draft | undefined;
};

type SetDraftInProgressAction = {
  type: string;
  payload: string | undefined;
};

type SetNewDraftFieldsAction = {
  type: string;
  payload: DraftUploadFields;
};

type SetNewDraftFileUpload = {
  type: string;
  payload: DraftFileOrUrlUploaded | undefined;
};

const initialDrafts: DraftsState = {
  userDrafts: [],
  newDraft: undefined,
  currentDraft: undefined,
  draftInProgress: undefined,
  newDraftFields: {
    target_audience: '',
    other_instructions: ''
  },
  fileOrUrlsUploaded: undefined
};

const sortDraftsByDate = (a: DraftListType, b: DraftListType) =>
  a.created_at > b.created_at ? -1 : 1;

export const draftsSlice = createSlice({
  name: 'drafts',
  initialState: initialDrafts,
  reducers: {
    setDrafts: (state, action: PayloadAction<DraftListType[]>) => {
      state.userDrafts = action.payload.sort(sortDraftsByDate);
      const newDraft = action.payload.find(
        (draft) =>
          draft.versions.length === 0 ||
          draft.versions.some(
            (version) => version.status === 'received' || version.status === 'in_progress'
          )
      );

      state.newDraft = newDraft;
      if (
        newDraft &&
        newDraft.versions.some(
          (version) => version.status === 'received' || version.status === 'in_progress'
        )
      ) {
        state.draftInProgress = newDraft.id;
      } else {
        state.draftInProgress = undefined;
      }
    },
    setNewDraft: (state, action: PayloadAction<DraftListType | undefined>) => {
      state.newDraft = action.payload;
    },
    setCurrentDraft: (state, action: SetCurrentDraftAction) => {
      state.currentDraft = action.payload;
    },
    setDraftInProgress: (state, action: SetDraftInProgressAction) => {
      state.draftInProgress = action.payload;
    },
    setNewDraftFields: (state, action: SetNewDraftFieldsAction) => {
      state.newDraftFields = action.payload;
    },
    setFilesOrUrlsUploaded: (state, action: SetNewDraftFileUpload) => {
      state.fileOrUrlsUploaded = action.payload;
    }
  }
});

export const {
  setDrafts,
  setCurrentDraft,
  setDraftInProgress,
  setNewDraft,
  setNewDraftFields,
  setFilesOrUrlsUploaded
} = draftsSlice.actions;

export default draftsSlice.reducer;
