import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

type TabPayload = {
  tabIndex: number;
};

const initialTab: TabPayload = {
  tabIndex: 0
};

export const tabSlice = createSlice({
  name: 'tab',
  initialState: initialTab,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload;
    }
  }
});

export const { setTab } = tabSlice.actions;

export default tabSlice.reducer;
