import React, { FC } from 'react';
import UploadCard from './UploadCard';
import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';
import { UPLOAD_SOURCE_FILE_TEXT } from './constants';
import { Draft } from '../drafts/types';

type UploadDropzoneType = {
  // eslint-disable-next-line no-unused-vars
  handleRemove: (fileId: string, draftId: string) => void;
  draft: Draft | undefined;
  refreshDraft: () => void;
  isGenerated: boolean;
};

const UploadDropzone: FC<UploadDropzoneType> = ({
  handleRemove,
  refreshDraft,
  draft,
  isGenerated
}) => {
  return (
    <Wrapper sx={{ width: '100%' }}>
      <SectionContainer>
        <SectionText>{UPLOAD_SOURCE_FILE_TEXT.sectionText}</SectionText>
      </SectionContainer>
      <UploadCard
        refreshDraft={refreshDraft}
        draft={draft}
        handleRemove={handleRemove}
        isGenerated={isGenerated}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SectionText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;

const SectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export default UploadDropzone;
