import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_ENDPOINT, AUTH_COOKIE_NAME, PROJECT_NAME } from '../../constants';
import { Backdrop, Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { setUser } from '../../store/authSlice';

type Props = {
  isMobile: boolean;
};

export default function SignIn({ isMobile }: Props) {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [cookie, setCookie] = useCookies([AUTH_COOKIE_NAME]);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>();
  const [submitted, setSubmitted] = useState(false);

  const handleSignIn = () => {
    setError(undefined);
    setSubmitted(true);
    fetch(`${API_ENDPOINT}/api/auth/sign-in/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password, project: PROJECT_NAME })
    })
      .then((data) => data.json())
      .then((res) => {
        if (res.token) {
          setCookie(AUTH_COOKIE_NAME, res.token);
          dispatch(setUser({ name: 'User', email, token: res.token }));
        }
        if (res.email) {
          setError(res.email[0]);
          setSubmitted(false);
        }
      })
      .catch(() => {
        setError('Incorrect email/password');
        setSubmitted(false);
      });
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.code === 'Enter') {
      handleSignIn();
    }
  };

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={true}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={isMobile ? '80%' : '500px'}
        height="70%"
        padding={5}
        sx={{ backgroundColor: '#fff' }}
      >
        <Typography variant="h4" marginBottom={10}>
          ClientCatch Login
        </Typography>
        <TextField
          sx={{ width: 350, mb: 2 }}
          InputLabelProps={{ shrink: true }}
          label="Email"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <TextField
          sx={{ width: 350, mb: 2 }}
          InputLabelProps={{ shrink: true }}
          label="Password"
          type="password"
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          onKeyDown={handleKeyDown}
        />
        {!!error && <span style={{ color: 'red', marginBottom: 16 }}>{error}</span>}
        <Button
          variant="contained"
          onClick={handleSignIn}
          sx={{ width: 200 }}
          disabled={submitted}
          endIcon={submitted ? <CircularProgress size={14} sx={{ ml: 2 }} /> : null}
        >
          Sign In
        </Button>
      </Box>
    </Backdrop>
  );
}
