import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { TOP_MENU_HEIGHT } from '../../constants';
import { LinksGroup, LoginButtons } from './CustomLinksButtons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getAvatar } from './utils';
import { useNavigate } from 'react-router-dom';
import { setCurrentDraft } from '../../store/draftsSlice';
import { setTab } from '../../store/tabSlice';
import { FILE_TAB } from '../upload/constants';

export default function DesktopMenu() {
  const auth = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Wrapper className="Wrapper">
      <img
        height={21}
        alt="logo"
        src="/ClientCatchLogo2.png"
        onClick={() => {
          dispatch(setCurrentDraft(undefined));
          dispatch(setTab(FILE_TAB));
          navigate('/');
        }}
        style={{ cursor: 'pointer' }}
      />
      <Box display="flex" alignItems="center">
        <LinksGroup />
        {auth.loggedIn ? (
          getAvatar(auth.user?.name ?? 'User', false)
        ) : (
          <LoginButtons isMobile={false} />
        )}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  height: ${TOP_MENU_HEIGHT}px;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
`;
