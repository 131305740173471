import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import styled from '@emotion/styled';
import {
  DOCUMENTS_LIST_VIEWER_WIDTH,
  TOP_MENU_HEIGHT,
  TOP_MENU_MOBILE_HEIGHT
} from '../../constants';
import DraftListItem from './DraftListItem';
import DeleteDialog from '../DeleteDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { getApiClient, logOutIfUnauthorized } from '../../utils';
import { DraftListType } from './types';
import { createNewDraft } from './utils';
import { setSnackbar } from '../../store/snackbarSlice';
import { setDraftInProgress, setNewDraftFields } from '../../store/draftsSlice';
import { setMobileDrawerState } from '../../store/mobileDrawerSlice';
import { setTab } from '../../store/tabSlice';
import { FILE_TAB } from '../upload/constants';

type Props = {
  isMobile: boolean;
  getAllDrafts: () => void;
};

export default function DraftsListViewer({ isMobile, getAllDrafts }: Props) {
  const { id } = useParams();
  const drafts = useSelector<RootState, DraftListType[]>((state) => state.drafts.userDrafts);
  const newDraft = useSelector<RootState, DraftListType | undefined>(
    (state) => state.drafts.newDraft
  );
  const [draftIdToDelete, setDraftIdToDelete] = useState<string>();
  const navigate = useNavigate();
  const draftInProgress = useSelector((state: RootState) => state.drafts.draftInProgress);
  const dispatch = useDispatch();

  const confirmDraftDelete = (draftId: string) => {
    setDraftIdToDelete(draftId);
  };

  const handleDraftDelete = () => {
    const apiClient = getApiClient();
    if (apiClient) {
      if (draftInProgress === draftIdToDelete) {
        dispatch(setDraftInProgress(undefined));
      }
      apiClient
        .delete(`/api/client-catch/drafts/${draftIdToDelete}/`)
        .then(() => {
          getAllDrafts();
          setDraftIdToDelete(undefined);
          if (draftIdToDelete === newDraft?.id) {
            dispatch(setNewDraftFields({ target_audience: '', other_instructions: '' }));
          }
          if (draftIdToDelete === id) {
            navigate('/');
          }
        })
        .catch((err) => {
          if (logOutIfUnauthorized(err)) {
            return;
          }
          dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
        });
    }
  };

  const handleNewDraft = () => {
    if (newDraft) {
      navigate(`/preview/${newDraft.id}`);
      if (isMobile) {
        dispatch(
          setMobileDrawerState({
            open: true,
            position: 'right',
            content: 'upload'
          })
        );
      }
      return;
    }
    const newDraftResponse = createNewDraft();
    if (newDraftResponse) {
      newDraftResponse
        .then((response) => {
          if (isMobile) {
            dispatch(
              setMobileDrawerState({
                open: true,
                position: 'right',
                content: 'upload'
              })
            );
          }
          dispatch(setTab(FILE_TAB));
          navigate(`/preview/${response.data.id}`);
          getAllDrafts();
        })
        .catch((err) => logOutIfUnauthorized(err?.response));
    }
  };

  return (
    <Wrapper mobile={isMobile ? 1 : 0}>
      <CustomButton variant="contained" startIcon={<Add />} onClick={handleNewDraft}>
        New Draft
      </CustomButton>
      {drafts?.map((draft) => (
        <DraftListItem
          key={draft.id}
          draft={draft}
          confirmDraftDelete={confirmDraftDelete}
          isMobile={isMobile}
        />
      ))}

      {draftIdToDelete && (
        <DeleteDialog
          handleDelete={handleDraftDelete}
          handleCloseDialog={() => setDraftIdToDelete(undefined)}
          title="Are you sure want to delete this draft?"
          body="If you delete the draft you can’t recover it."
        />
      )}
    </Wrapper>
  );
}

const CustomButton = styled(Button)`
  width: 220px;
  align-self: center;
  margin: 16px 0;
  line-height: unset;
  text-transform: none;
`;
type WrapperProps = {
  mobile: number;
};

const Wrapper = styled(Box)`
  background-color: white;
  width: ${(props: WrapperProps) =>
    props.mobile === 1 ? '80vw' : `${DOCUMENTS_LIST_VIEWER_WIDTH}px`};
  height: calc(
    100vh -
      ${(props: WrapperProps) =>
        props.mobile === 1 ? TOP_MENU_MOBILE_HEIGHT : TOP_MENU_HEIGHT + 1}px
  );
  margin-top: ${(props: WrapperProps) => (props.mobile === 1 ? TOP_MENU_MOBILE_HEIGHT : 1)}px;
  display: flex;
  flex-direction: column;
`;
