import React from 'react';
import { Box } from '@mui/material';
import { TOP_MENU_MOBILE_HEIGHT, UPLOAD_VIEWER_WIDTH } from '../../constants';
import styled from '@emotion/styled';
import UploadTabView from './UploadTabView';
import { theme } from '../../theme';
import { Draft } from '../drafts/types';

type Props = {
  draft: Draft | undefined;
  refreshDraft: () => void;
};

export default function DocumentsUpload({ draft, refreshDraft }: Props) {
  return (
    <DocumentsUploadWrapper className="Wrapper">
      <UploadTabView draft={draft} refreshDraft={refreshDraft} />
    </DocumentsUploadWrapper>
  );
}

const DocumentsUploadWrapper = styled(Box)`
  border-radius: 4px;
  padding: 16px;
  margin-left: 16px;
  width: ${UPLOAD_VIEWER_WIDTH}px;
  min-width: ${UPLOAD_VIEWER_WIDTH}px;

  ${theme.breakpoints.between('md', 'lg')} {
    width: 40vw;
    margin-left: 0;
    margin-top: ${TOP_MENU_MOBILE_HEIGHT}px;
  }

  ${theme.breakpoints.between('sm', 'md')} {
    width: 60vw;
    margin-left: 0;
    margin-top: ${TOP_MENU_MOBILE_HEIGHT}px;
  }

  ${theme.breakpoints.down('sm')} {
    width: 80vw;
    margin-left: 0;
    margin-top: ${TOP_MENU_MOBILE_HEIGHT}px;
  }
`;
