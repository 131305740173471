import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import styled from '@emotion/styled';
import DeleteIcon from '../assets/DeleteIcon';

type Props = {
  handleDelete: () => void;
  handleCloseDialog: () => void;
  title: string;
  body: string;
};

export default function DeleteDialog({ handleDelete, handleCloseDialog, title, body }: Props) {
  return (
    <Dialog open={true}>
      <CustomDialogTitle>
        <DeleteBox>
          <DeleteIcon />
        </DeleteBox>
        {title}
      </CustomDialogTitle>
      <DialogContent sx={{ padding: 1, margin: 0 }}>
        <DialogContentText textAlign="center" fontSize={12}>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: 2 }}>
        <CustomButton variant="outlined" onClick={handleCloseDialog}>
          Cancel
        </CustomButton>
        <CustomButton variant="contained" onClick={handleDelete} color="error">
          Delete
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

const CustomButton = ({ children, ...props }: React.ComponentProps<typeof Button>) => (
  <Button size="small" sx={{ textTransform: 'none', fontSize: 14, borderRadius: 1 }} {...props}>
    {children}
  </Button>
);

const DeleteBox = styled(Box)`
  background-color: #fde3e3;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  padding-bottom: 0;
`;
