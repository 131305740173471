import styled from '@emotion/styled';
import { Box } from '@mui/material';
import {
  DOCUMENTS_LIST_VIEWER_WIDTH,
  TOP_MENU_HEIGHT,
  TOP_MENU_MOBILE_HEIGHT
} from '../../constants';
import { DraftVersionFeedback } from './types';

export const positiveFeedbackTags = [
  'Legally accurate',
  'Factually accurate',
  'Complete',
  'Actionable',
  'Well-targeted'
];
export const negativeFeedbackTags = [
  'Missing relevant info',
  'Includes irrelevant info',
  'Factually inaccurate',
  'Legally inaccurate',
  'Not well-targeted',
  'Wrong style'
];

type DraftWrapperProps = {
  mobile: number;
};

export const DraftWrapper = styled(Box)`
  display: flex;
  width: ${(props: DraftWrapperProps) =>
    props.mobile === 1 ? '100vw' : `calc(100vw - ${DOCUMENTS_LIST_VIEWER_WIDTH}px)`};
  height: calc(
    100vh - ${(props) => (props.mobile === 1 ? TOP_MENU_MOBILE_HEIGHT : TOP_MENU_HEIGHT)}px -
      ${(props) => (props.mobile === 1 ? 33 : 48)}px
  );
  padding: ${(props) => (props.mobile === 1 ? 16 : 24)}px;
`;

export const INITIAL_FEEDBACK: DraftVersionFeedback = {
  additional: '',
  positive: false,
  tags: ''
};

export const REFRESH_INTERVAL = 30 * 1000;
