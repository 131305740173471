import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { YOUR_TARGER_AUDIENCE_TEXT, OTHER_INSTRUCTIONS_TEXT } from './constants';
import styled from '@emotion/styled';
import { Button, Box, TextField, Typography } from '@mui/material';
import { theme } from '../../theme';
import UploadDropzone from './UploadDropzone';
import { FILE_TAB, URL_TAB } from './constants';
import { getApiClient, logOutIfUnauthorized } from '../../utils';
import { Draft } from '../drafts/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  DraftFileOrUrlUploaded,
  DraftUploadFields,
  setDraftInProgress,
  setNewDraftFields
} from '../../store/draftsSlice';
import { setSnackbar } from '../../store/snackbarSlice';
import UrlsZone from './UrlsZone';
import { isDraftGenerated } from './utils';
import { RootState } from '../../store/store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MOBILE_BREAKPOINT } from '../../constants';
import { setMobileDrawerState } from '../../store/mobileDrawerSlice';
import { setLoading } from '../../store/loadingSlice';
import { setTab } from '../../store/tabSlice';
import { useParams } from 'react-router-dom';

type Props = {
  draft: Draft | undefined;
  refreshDraft: () => void;
};

const UploadForm = ({ draft, refreshDraft }: Props) => {
  const { id } = useParams();
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT);
  const draftUpdateRef = useRef(false);
  const [targetAudience, setTargetAudience] = useState<string>('');
  const [otherInstructions, setOtherInstructions] = useState<string>('');
  const dispatch = useDispatch();
  const tabIndex = useSelector<RootState, number>((state) => state.tab.tabIndex);
  const newDraftFields = useSelector<RootState, DraftUploadFields>(
    (state) => state.drafts.newDraftFields
  );
  const fileOrUrlsUploaded = useSelector<RootState, DraftFileOrUrlUploaded | undefined>(
    (state) => state.drafts.fileOrUrlsUploaded
  );

  useEffect(() => {
    draftUpdateRef.current = true;
  }, [id]);

  useEffect(() => {
    if (draft?.id === id && draftUpdateRef.current) {
      if (isDraftGenerated(draft)) {
        setTargetAudience(draft?.versions[0]?.target_audience ?? '');
        setOtherInstructions(draft?.versions[0]?.other_instructions ?? '');
      } else {
        setTargetAudience(newDraftFields.target_audience);
        setOtherInstructions(newDraftFields.other_instructions);
      }

      draftUpdateRef.current = false;
      if ((fileOrUrlsUploaded?.filesUploaded?.length || 0) > 0) {
        dispatch(setTab(FILE_TAB));
      } else if ((fileOrUrlsUploaded?.urlsUploaded?.length || 0) > 0) {
        dispatch(setTab(URL_TAB));
      } else if ((draft?.files?.length || 0) > 0) {
        dispatch(setTab(FILE_TAB));
      } else if ((draft?.urls?.length || 0) > 0) {
        dispatch(setTab(URL_TAB));
      }
    }
    if (!draft) {
      setTargetAudience('');
      setOtherInstructions('');
    }
  }, [draft]);

  const isGenerateButtonDisabled = () => {
    if (isDraftGenerated(draft)) {
      return true;
    }

    return (draft?.files?.length ?? 0) === 0 && (draft?.urls.length ?? 0) === 0;
  };

  const handleRemove = (fileId: string, draftId: string) => {
    const apiClient = getApiClient();
    if (apiClient) {
      dispatch(setLoading(true));
      apiClient
        .delete(`/api/client-catch/drafts/${draftId}/files/${fileId}/`)
        .then(() => {
          refreshDraft();
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (logOutIfUnauthorized(err?.response)) {
            return;
          }
          dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
        });
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const apiClient = getApiClient();
    if (!apiClient) return;

    dispatch(setLoading(true));
    apiClient
      .post(`/api/client-catch/drafts/${draft?.id}/versions/`, {
        target_audience: targetAudience,
        other_instructions: otherInstructions
      })
      .then(() => {
        refreshDraft();
        dispatch(setLoading(false));
        dispatch(setDraftInProgress(draft?.id));
        if (isMobile) {
          dispatch(
            setMobileDrawerState({
              open: false,
              position: 'left',
              content: 'drafts'
            })
          );
        }
        dispatch(
          setNewDraftFields({
            target_audience: '',
            other_instructions: ''
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (logOutIfUnauthorized(err?.response)) {
          return;
        }
        dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
      });
  };
  return (
    <Form onSubmit={handleSubmit}>
      {tabIndex === FILE_TAB && (
        <UploadDropzone
          handleRemove={handleRemove}
          draft={draft}
          refreshDraft={refreshDraft}
          isGenerated={isDraftGenerated(draft)}
        />
      )}
      {tabIndex === URL_TAB && (
        <UrlsZone draft={draft} refreshDraft={refreshDraft} isGenerated={isDraftGenerated(draft)} />
      )}

      <SectionContainer>
        <SectionText>{YOUR_TARGER_AUDIENCE_TEXT.sectionText}</SectionText>
        <TextField
          size="small"
          fullWidth={true}
          multiline={true}
          rows={4}
          value={targetAudience}
          placeholder={YOUR_TARGER_AUDIENCE_TEXT.placeholderText}
          inputProps={{ style: { fontSize: '14px' } }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
          onChange={(event) => setTargetAudience(event.target.value)}
          onBlur={() =>
            dispatch(setNewDraftFields({ ...newDraftFields, target_audience: targetAudience }))
          }
          disabled={isDraftGenerated(draft)}
        />
      </SectionContainer>

      <SectionContainer>
        <SectionText>{OTHER_INSTRUCTIONS_TEXT.sectionText}</SectionText>
        <TextField
          size="small"
          value={otherInstructions}
          fullWidth={true}
          multiline={true}
          rows={3}
          placeholder={OTHER_INSTRUCTIONS_TEXT.placeholderText}
          inputProps={{ style: { fontSize: '14px' } }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
          onChange={(event) => setOtherInstructions(event.target.value)}
          onBlur={() =>
            dispatch(
              setNewDraftFields({ ...newDraftFields, other_instructions: otherInstructions })
            )
          }
          disabled={isDraftGenerated(draft)}
        />
      </SectionContainer>
      <SubmitButton variant="contained" type="submit" disabled={isGenerateButtonDisabled()}>
        Generate
      </SubmitButton>
      <HelpButton variant="text">Need help?</HelpButton>
    </Form>
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  width: 100%;
  height: 100%;
`;

const SubmitButton = styled(Button)`
  background-color: ${theme.palette.primary.main};
  color: white;
  text-transform: none;
  width: 100%;

  &.Mui-disabled {
    background-color: #b8dcfa;
    color: white;
  }
`;

const HelpButton = styled(Button)`
  text-transform: none;
  width: 100%;
`;

const SectionText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;

const SectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 8px;
`;

export default UploadForm;
