import React, { useState } from 'react';
import { TextField, Box } from '@mui/material';
import { isValidUrl } from './utils';
import UrlCard from './UrlCard';
import { UrlObject } from './types';

type Props = {
  urlObject: UrlObject;
  // eslint-disable-next-line no-unused-vars
  handleUpdate: (urlObject: UrlObject) => void;
  // eslint-disable-next-line no-unused-vars
  handleDelete: (urlObjectId: string) => void;
  isGenerated: boolean;
  isMaxAmountReached: boolean;
};

export default function UrlInput({
  urlObject,
  handleUpdate,
  handleDelete,
  isGenerated,
  isMaxAmountReached
}: Props) {
  const [urlValue, setUrlValue] = useState<string>(urlObject.draftUrl.url);
  const [error, setError] = useState<boolean>(!urlObject.isValid && urlObject.draftUrl.url !== '');

  const hasError = () => {
    if (!urlValue || urlValue.trim() === '') {
      return false;
    }

    return !isValidUrl(urlValue);
  };

  const handleBlur = () => {
    const error = hasError();
    setError(error);
    if (urlObject.draftUrl.url !== urlValue) {
      handleUpdate({ ...urlObject, draftUrl: { ...urlObject.draftUrl, url: urlValue } });
    }
  };

  const handleKeyUp = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.code === 'Enter') {
      handleBlur();
    }
  };

  return (
    <Box display="flex" alignItems={error ? 'flex-start' : 'center'}>
      {urlObject.isValid ? (
        <UrlCard
          draftUrl={urlObject.draftUrl}
          isGenerated={isGenerated}
          handleDelete={handleDelete}
        />
      ) : (
        <TextField
          fullWidth
          label="Enter URL"
          variant="outlined"
          size="small"
          inputProps={{ style: { fontSize: '14px' } }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
          value={urlValue}
          onChange={(e) => setUrlValue(e.target.value)}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          error={error}
          disabled={isGenerated || isMaxAmountReached}
          helperText={error ? 'URL is invalid. Check the URL format.' : undefined}
        />
      )}
    </Box>
  );
}
