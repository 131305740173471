import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import UploadForm from './UploadForm';
import { theme } from '../../theme';
import { Draft } from '../drafts/types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { setTab } from '../../store/tabSlice';

type Props = {
  draft: Draft | undefined;
  refreshDraft: () => void;
};

export default function UploadTabView({ draft, refreshDraft }: Props) {
  const tabIndex = useSelector<RootState, number>((state) => state.tab.tabIndex);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setTab(newValue));
  };

  return (
    <Wrapper>
      <Title>Let&apos;s create your custom Alert!</Title>
      <TabWrapper>
        <AllTabs value={tabIndex} onChange={handleChange} variant="fullWidth">
          <Tab label="Files" />
          <Tab label="URLs" />
        </AllTabs>
      </TabWrapper>
      <ContentWrapper>
        <UploadForm draft={draft} refreshDraft={refreshDraft} />
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: 100%;
`;

const TabWrapper = styled(Box)`
  display: flex;
  width: 100%;
  background-color: #f3f6f8;
`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
  padding: 4px;
`;

const AllTabs = styled(Tabs)`
  width: 100%;
  padding: 8px;
  min-height: 32px;
  & .MuiTab-root {
    text-transform: none;
    min-height: 32px;
  }
  & .MuiTab-root.Mui-selected {
    background-color: ${theme.palette.primary.main};
    color: white;
    border-radius: 6px;
  }
  & .MuiTabs-indicator {
    display: none;
  }
`;

const ContentWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
