import React, { Fragment } from 'react';
import { CircularProgress, SxProps } from '@mui/material';

type Props = {
  sxProps?: SxProps;
};

export default function GradientCircularProgress({ sxProps }: Props) {
  return (
    <Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#0C62A7" />
            <stop offset="100%" stopColor="#c8eaf7" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' }, ...sxProps }} />
    </Fragment>
  );
}
