// eslint-disable-next-line no-undef
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// eslint-disable-next-line no-undef
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const AUTH_COOKIE_NAME = 'token';
export const MOBILE_BREAKPOINT = '(max-width:1024px)';
export const TOP_MENU_HEIGHT = 60;
export const TOP_MENU_MOBILE_HEIGHT = 46;
export const DOCUMENTS_LIST_VIEWER_WIDTH = 260;
export const UPLOAD_VIEWER_WIDTH = 357;
export const DOCUMENT_PREVIEW_STYLE = {
  width: `calc(100vw - ${DOCUMENTS_LIST_VIEWER_WIDTH}px)`,
  height: `calc(100vh - ${TOP_MENU_HEIGHT}px - 48px)`,
  padding: 24
};
export const DOCUMENT_PREVIEW_MOBILE_STYLE = {
  width: '100vw',
  height: `calc(100vh - ${TOP_MENU_MOBILE_HEIGHT}px - 33px)`,
  padding: 16
};
export const HTTP_RESPONSE_STATUS_UNAUTHORIZED = 401;
export const PROJECT_NAME = 'client_catch';
