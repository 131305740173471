import { createSlice } from '@reduxjs/toolkit';

type SnackbarPayload = {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info';
};

type Action = {
  payload: SnackbarPayload;
  type: string;
};

const initialSnackbar: SnackbarPayload = {
  open: false,
  message: '',
  severity: 'success'
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: { snackbar: initialSnackbar },
  reducers: {
    setSnackbar: (state, action: Action) => {
      state.snackbar = action.payload;
    }
  }
});

export const { setSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
