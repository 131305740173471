import { createSlice } from '@reduxjs/toolkit';

type Action = {
  payload: boolean;
  type: string;
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: { isLoading: 0 },
  reducers: {
    setLoading: (state, action: Action) => {
      state.isLoading = action.payload ? state.isLoading + 1 : state.isLoading - 1;
    }
  }
});

export const { setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
