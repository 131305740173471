import React, { useState } from 'react';
import { Backdrop, Box, Drawer, IconButton, SpeedDial, SpeedDialAction } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AddIcon from '@mui/icons-material/Add';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import styled from '@emotion/styled';
import { TOP_MENU_MOBILE_HEIGHT } from '../../constants';
import DraftsListViewer from '../drafts/DraftsListViewer';
import DocumentsUpload from '../upload/DocumentsUpload';
import MobileLeftMenu from './MobileLeftMenu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { setMobileDrawerState } from '../../store/mobileDrawerSlice';
import { getAvatar } from './utils';
import { Draft } from '../drafts/types';
import { createNewDraft, fetchDraft } from '../drafts/utils';
import { AxiosResponse } from 'axios';
import { setCurrentDraft } from '../../store/draftsSlice';
import { setSnackbar } from '../../store/snackbarSlice';
import { setLoading } from '../../store/loadingSlice';
import { setTab } from '../../store/tabSlice';
import { FILE_TAB } from '../upload/constants';
import { logOutIfUnauthorized } from '../../utils';

type Props = {
  getAllDrafts: () => void;
};

export default function MobileMenu({ getAllDrafts }: Props) {
  const { id } = useParams();
  const drawerState = useSelector((state: RootState) => state.mobileDrawer.mobileDrawerState);
  const [showSpeedMenu, setShowSpeedMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const draft = useSelector((state: RootState) => state.drafts.currentDraft);
  const newDraft = useSelector((state: RootState) => state.drafts.newDraft);

  const refreshDraft = () => {
    dispatch(setLoading(true));
    const draftResponse = fetchDraft(id!);
    if (draftResponse) {
      draftResponse
        .then((response: AxiosResponse<Draft>) => {
          dispatch(setCurrentDraft(response.data));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (logOutIfUnauthorized(err?.response)) {
            return;
          }
          dispatch(
            setSnackbar({
              open: true,
              message: 'Draft not found',
              severity: 'error'
            })
          );
          navigate('/');
        });
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Error loading draft.',
          severity: 'error'
        })
      );
      dispatch(setLoading(false));
    }
  };

  const handleOpenSpeedMenu = () => setShowSpeedMenu(true);
  const handleCloseSpeedMenu = () => setShowSpeedMenu(false);
  const actions = [
    {
      name: 'Drafts',
      icon: <DescriptionIcon color="primary" />,
      onClick: (ev: React.MouseEvent) => {
        ev.stopPropagation();
        handleCloseSpeedMenu();
        dispatch(
          setMobileDrawerState({
            open: true,
            position: 'left',
            content: 'drafts'
          })
        );
      }
    },
    {
      name: 'Preview',
      icon: <DriveFileRenameOutlineIcon color="primary" />,
      onClick: (ev: React.MouseEvent) => {
        ev.stopPropagation();
        handleCloseSpeedMenu();
        dispatch(
          setMobileDrawerState({
            open: false,
            position: 'left',
            content: 'drafts'
          })
        );
      }
    },
    {
      name: id ? 'Uploads' : 'New',
      icon: <AddIcon color="primary" />,
      onClick: (ev: React.MouseEvent) => {
        ev.stopPropagation();
        handleCloseSpeedMenu();

        if (id) {
          dispatch(
            setMobileDrawerState({
              open: true,
              position: 'right',
              content: 'upload'
            })
          );
          return;
        }

        if (newDraft) {
          dispatch(
            setMobileDrawerState({
              open: true,
              position: 'right',
              content: 'upload'
            })
          );
          navigate(`/preview/${newDraft.id}`);
          return;
        }

        dispatch(setLoading(true));
        const newDraftResponse = createNewDraft();
        if (newDraftResponse) {
          newDraftResponse
            .then((response) => {
              dispatch(setTab(FILE_TAB));
              getAllDrafts();
              dispatch(
                setMobileDrawerState({
                  open: true,
                  position: 'right',
                  content: 'upload'
                })
              );
              navigate(`/preview/${response.data.id}`);
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
              if (logOutIfUnauthorized(err?.response)) {
                return;
              }
              dispatch(setSnackbar({ open: true, message: err.message, severity: 'error' }));
            });
        }
      }
    }
  ];

  const toggleTopMenu = () => {
    if (drawerState.open && drawerState.content === 'menu') {
      dispatch(setMobileDrawerState({ ...drawerState, open: false }));
    } else {
      dispatch(
        setMobileDrawerState({
          open: true,
          position: 'left',
          content: 'menu'
        })
      );
    }
  };

  return (
    <Box
      height={TOP_MENU_MOBILE_HEIGHT}
      className="Wrapper TopMenu"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 8px',
        alignItems: 'center'
      }}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box>
        <img
          width={135}
          height={18}
          alt="logo"
          src="/ClientCatchLogo2.png"
          onClick={() => {
            dispatch(setCurrentDraft(undefined));
            dispatch(setTab(FILE_TAB));
            navigate('/');
          }}
        />
      </Box>
      <Box display="flex" alignItems="center">
        {auth.loggedIn && getAvatar(auth.user?.name ?? 'User', true)}
        <IconButton onClick={toggleTopMenu}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Drawer
        anchor="left"
        open={drawerState.open && drawerState.position === 'left'}
        onClose={() => dispatch(setMobileDrawerState({ ...drawerState, open: false }))}
      >
        {drawerState.content === 'drafts' && (
          <DraftsListViewer isMobile={true} getAllDrafts={getAllDrafts} />
        )}
        {drawerState.content === 'menu' && <MobileLeftMenu />}
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerState.open && drawerState.position === 'right'}
        onClose={() => dispatch(setMobileDrawerState({ ...drawerState, open: false }))}
      >
        <DocumentsUpload draft={draft} refreshDraft={refreshDraft} />
      </Drawer>
      <CustomSpeedDial
        ariaLabel="Drafts action"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        icon={<AutoAwesomeIcon fontSize="small" />}
        open={showSpeedMenu}
        onClick={handleOpenSpeedMenu}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.onClick}
            FabProps={{ sx: { backgroundColor: '#BDDFFA' } }}
          />
        ))}
      </CustomSpeedDial>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpeedMenu}
        onClick={handleCloseSpeedMenu}
      />
    </Box>
  );
}

const CustomSpeedDial = styled(SpeedDial)`
  position: absolute;
  bottom: 32px;
  right: 32px;
  & .MuiSpeedDialAction-staticTooltipLabel {
    background-color: transparent;
    color: white;
    box-shadow: none;
  }
`;
