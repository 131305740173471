import React, { useState } from 'react';
import { Box, Link, MenuItem, Tooltip } from '@mui/material';
import { ContentCopyOutlined, EmailOutlined, FileDownloadOutlined } from '@mui/icons-material';
import { htmlToText } from 'html-to-text';
import { CustomMenu } from './DraftListItem';
import { DraftVersion } from './types';

type Props = {
  version: DraftVersion;
};

export default function DraftActions({ version }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement | HTMLElement>(null);
  const [showCopyClipboardMessage, setShowCopyClipboardMessage] = useState(false);
  const [showSentEmailMessage, setShowSentEmailMessage] = useState(false);

  const handleCloseDownloadOptions = () => {
    setAnchorEl(null);
  };

  const handleSendEmail = () => {
    setShowSentEmailMessage(true);
  };

  return (
    <Box>
      <Tooltip
        title="Copied to clipboard"
        placement="bottom"
        onClose={() => setShowCopyClipboardMessage(false)}
        open={showCopyClipboardMessage}
      >
        <ContentCopyOutlined
          color="primary"
          fontSize="small"
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            navigator.clipboard
              .writeText(htmlToText(version.result_html))
              .then(() => setShowCopyClipboardMessage(true))
          }
        />
      </Tooltip>
      <FileDownloadOutlined
        color="primary"
        fontSize="small"
        sx={{ ml: 2, cursor: 'pointer' }}
        onClick={(event: React.MouseEvent<SVGSVGElement | HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
      />
      <CustomMenu
        anchorEl={anchorEl}
        id="download-menu"
        open={!!anchorEl}
        onClose={handleCloseDownloadOptions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        disableAutoFocusItem
      >
        <MenuItem dense selected={false} onClick={handleCloseDownloadOptions}>
          <Link href={version.result_pdf_url} target="_blank" underline="none">
            Pdf
          </Link>
        </MenuItem>
        <MenuItem dense selected={false} onClick={handleCloseDownloadOptions}>
          <Link href={version.result_url} target="_blank" underline="none">
            Word
          </Link>
        </MenuItem>
      </CustomMenu>
      <Tooltip
        title="Email was sent"
        placement="bottom"
        onClose={() => setShowSentEmailMessage(false)}
        open={showSentEmailMessage}
      >
        <EmailOutlined
          color="primary"
          fontSize="small"
          sx={{ ml: 2, cursor: 'pointer' }}
          onClick={handleSendEmail}
        />
      </Tooltip>
    </Box>
  );
}
