import axios, { AxiosResponse } from 'axios';
import { store } from './store/store';
import { API_ENDPOINT, AUTH_COOKIE_NAME, HTTP_RESPONSE_STATUS_UNAUTHORIZED } from './constants';

export const getApiClient = () => {
  const auth = store.getState().auth;
  if (!auth.loggedIn || !auth.user?.token) {
    return undefined;
  }

  return axios.create({
    // eslint-disable-next-line no-undef
    baseURL: API_ENDPOINT,
    headers: {
      Authorization: `Bearer ${auth.user.token}`
    }
  });
};

export const logOutIfUnauthorized = (response: AxiosResponse | undefined) => {
  if (response && response.status === HTTP_RESPONSE_STATUS_UNAUTHORIZED) {
    document.cookie = AUTH_COOKIE_NAME + '=; Max-Age=-99999999;';
    return true;
  }
  return false;
};
