import { Draft } from '../drafts/types';
import { useEffect, useRef } from 'react';
import { MAX_NUMBER_OF_FILES_OR_URLS } from './constants';

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const isDraftGenerated = (draft: Draft | undefined) => {
  return (draft?.versions?.length ?? 0) > 0;
};

export function useInterval(callback: () => void, interval: number) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, interval);
    return () => clearInterval(id);
  }, []);
}

export const getMaxAmountErrorMessage = () => {
  if (MAX_NUMBER_OF_FILES_OR_URLS === 1) {
    return `Only ${MAX_NUMBER_OF_FILES_OR_URLS} file/url is allowed.`;
  }

  return `Only ${MAX_NUMBER_OF_FILES_OR_URLS} files/urls are allowed.`;
};
