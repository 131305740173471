import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function UploadIcon() {
  return (
    <SvgIcon>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="#CFE8FC" />
        <rect
          x="0.5"
          y="0.5"
          width="43"
          height="43"
          rx="21.5"
          stroke="#6FBAF6"
          strokeDasharray="2 2"
        />
        <g clipPath="url(#clip0_142_5021)">
          <path
            d="M14 27V29C14 29.5304 14.2107 30.0391 14.5858 30.4142C14.9609 30.7893 15.4696 31 16 31H28C28.5304 31 29.0391 30.7893 29.4142 30.4142C29.7893 30.0391 30 29.5304 30 29V27"
            stroke="#0C62A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 19L22 14L27 19"
            stroke="#0C62A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 14V26"
            stroke="#0C62A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_142_5021">
            <rect width="24" height="24" fill="white" transform="translate(10 10)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
